































































































import Vue from 'vue';
import {TestStateDialog} from '~/components/student';
import {ExtraTryTests, School, Student, StudentTestState} from '~/models';
import {ConfirmDialog, TooltipButton, TooltipDate} from '../components/common';

export default Vue.extend({
  name: 'ProgressTrackerPage',
  components: {TooltipDate, TooltipButton, ConfirmDialog, TestStateDialog},
  data() {
    return {
      authorize: {dialog: false, studentTestState: null},
      authorizeAll: {dialog: false, studentId: null},
      confirm: {dialog: false, action: () => ({})},
      tabIndex: {},
      isLoading: false,
      resultsAvailable: false,
      receivedStudentIds: [],
      filter: {
        studentId: null,
        schoolId: null,
        search: '',
        progress: true,
        final: true,
        preExam: true,
      },
    };
  },
  async created() {
    document.title = 'LTMS - ProgressTracker';
    this.filter.progress = !(localStorage.getItem(`progressTracker.progress`) === 'false');
    this.filter.final = !(localStorage.getItem(`progressTracker.final`) === 'false');
    this.filter.preExam = !(localStorage.getItem(`progressTracker.preExam`) === 'false');
    this.filter.schoolId = localStorage.getItem(`progressTracker.schoolId`);
    this.filter.studentId = localStorage.getItem(`progressTracker.studentId`);

    if (this.filter.schoolId) {
      await this.onSchoolChange(this.filter.schoolId);
    }
    try {
      await this.$store.dispatch('school/fetchOptions');
    } catch (err) {
      await this.$store.dispatch('showError', err);
    }
  },
  computed: {
    schools(): any[] {
      const schools = School.query().all().sort((a, b) => a.name < b.name ? -1 : 1);
      return [{name: this.$t('label.noFlightSchoolAssigned'), id: '-1'}].concat(schools);
    },
    students(): Student[] {
      return Student.query()
          .with('testInstances')
          .with('tests')
          .with('testGroups.tests')
          .with('studentTestStates.test')
          .all();
    },
    schoolStudents(): Student[] {
      if (!this.filter.schoolId) {
        return [];
      }
      return Student.query().where('schoolId', this.filter.schoolId).all();
    },
    progressStudents(): any[] {
      const lowerSearch = this.filter.search.toLowerCase();
      return this.students
          .filter(student => student.fullName.toLowerCase().includes(lowerSearch))
          .filter(student => this.receivedStudentIds.includes(student.id))
          // .filter(s => this.filter.schoolId ? this.filter.schoolId === s.schoolId : true)
          // .filter(student => (this.filter.userType === 'all' && (student.type === 'presence' || student.type === 'distance')) || this.filter.userType === student.type)
          .map(student => {
            const passedInstances = student.testInstances.filter(instance => instance.passed);
            const sortedInstances = student.testInstances.sort((a, b) => new Date(b.finishedAt).getTime() - new Date(a.finishedAt).getTime());
            let tests = student.testGroups.reduce((arr, tg) => arr.concat(tg.tests), student.tests);
            tests = tests.concat(student.studentTestStates.map(sts => sts.test));
            tests = tests.filter((test, index, self) => self.findIndex(t => t.id === test.id) === index); // unique only
            tests = tests.filter(test => test.type !== 'default'); // only typed tests

            this.tabIndex[student.id] = this.tabIndex[student.id] || 0;
            const ps: any = {
              student,
              id: student.id,
              progressTests: tests.filter(test => test.isProgress).map(t => ({
                ...t,
                instance: sortedInstances.find(i => i.testId === t.id),
                instances: student.testInstances.filter(i => i.testId === t.id),
              })),
              passedProgressTestInstances: passedInstances.filter(instance => instance.isProgress),
              finalTests: tests.filter(test => test.isFinal).map(t => ({
                ...t,
                instance: sortedInstances.find(i => i.testId === t.id),
                instances: student.testInstances.filter(i => i.testId === t.id),
              })),
              passedFinalTestInstances: passedInstances.filter(instance => instance.isFinal),
              preExams: tests.filter(test => test.isPreExam).map(t => ({
                ...t,
                instance: sortedInstances.find(i => i.testId === t.id),
                instances: student.testInstances.filter(i => i.testId === t.id),
              })),
              passedPreExamInstances: passedInstances.filter(instance => instance.isPreExam),
              tests,
            };
            ps.progressTests = ps.progressTests.map(t => ({...t, status: this.getStatus(t, student)}));
            ps.finalTests = ps.finalTests.map(t => ({...t, status: this.getStatus(t, student)}));
            ps.preExams = ps.preExams.map(t => ({...t, status: this.getStatus(t, student)}));

            ps.tabs = [
              {type: 'preExam', tests: ps.preExams},
              {type: 'final', tests: ps.finalTests},
              {type: 'progress', tests: ps.progressTests},
            ];

            return ps;
          });
    },
    headers(): any[] {
      const headers: any[] = [
        {text: this.$t('label.forename'), value: 'student.forename'},
        {text: this.$t('label.surname'), value: 'student.surname'},
      ];
      if (this.filter.preExam) {
        headers.push({text: this.$tc('p.preExam', 2), value: 'passedPreExamInstances.length', align: 'right'});
      }
      if (this.filter.final) {
        headers.push({text: this.$tc('p.finalTest', 2), value: 'passedFinalTestInstances.length', align: 'right'});
      }
      if (this.filter.progress) {
        headers.push({text: this.$tc('p.progressTest', 2), value: 'passedProgressTestInstances.length', align: 'right'});
      }
      return headers;
    },
  },
  methods: {
    getStatus(test, student) {
      const passed = !!student.testInstances.find(instance => instance.testId === test.id && instance.passed);
      const usedTries = student.testInstances.filter(instance => instance.testId === test.id).length;
      const allowed = !test.dependentOnId || !!student.testInstances.find(instance => instance.testId === test.dependentOnId && instance.passed);

      if (passed && !this.hasExtraTry(student.id, test.id)) {
        return 'passed';
      }
      if ((usedTries > 0 && usedTries < test.tries) || usedTries >= test.tries && this.hasExtraTry(student.id, test.id)) {
        return 'attempted';
      }
      if (usedTries >= test.tries) {
        return 'notPassed';
      }
      if (!allowed) {
        return 'locked';
      }
      return 'available';
    },
    async onSearchClick() {
      this.isLoading = true;
      try {
        const receivedStudents = await this.$store.dispatch('student/fetchProgressTrackerData', this.filter);
        this.receivedStudentIds = receivedStudents.map(s => s.id);
        this.resultsAvailable = true;
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
      this.isLoading = false;
    },
    async onSchoolChange(schoolId) {
      if (schoolId === '-1') {
        return;
      }
      this.isLoading = true;
      this.updateLocalStorage('schoolId', schoolId);
      try {
        await this.$store.dispatch('school/fetchSchoolStudents', this.filter.schoolId);
      } catch (err) {
        await this.$store.dispatch('showError', err);
      }
      this.isLoading = false;
    },
    async onStudentChange(studentId) {
      this.updateLocalStorage('studentId', studentId);
    },
    updateLocalStorage(type, value) {
      localStorage.setItem(`progressTracker.${type}`, value);
    },
    getState(student, test) {
      const state = student.studentTestStates.find(sts => sts.testId === test.id)?.state;
      if (state) {
        return this.$t(`label.${state}`);
      }
      return this.$t('label.unauthorized');
    },
    getTestHeaders(type): any[] {
      const headers: any[] = [
        {text: this.$t('label.name'), value: 'displayName'},
        {text: this.$tc('p.try', 2), value: 'instances.length', align: 'center'},
        {text: this.$t('label.status'), value: 'status', align: 'center', sortable: false},
        {text: this.$t('label.lastResult'), value: 'instance.percentage', align: 'center'},
        {text: this.$t('label.submittedAt'), value: 'instance.finishedAt', align: 'right'},
      ];
      if (type === 'preExam') {
        headers.push({text: this.$t('label.state'), value: 'state', sortable: false, class: 'no-print-view', align: 'center'});
        headers.push({text: this.$t('label.actions'), value: 'actions', sortable: false, class: 'no-print-view', align: 'center', width: '90px'});
      }
      return headers;
    },
    onAuthorizeClick(studentId, testId): void {
      this.authorize.studentTestState = StudentTestState.query().where(sts => sts.studentId === studentId && sts.testId === testId).first() || {studentId, testId: testId};
      this.authorize.dialog = true;
    },
    giveExtraTry(studentId, test): void {
      if (test.status === 'passed') {
        this.confirm.dialog = true;
        this.confirm.action = () => this.$store.dispatch('test/giveAdditionalTry', {studentId, testId: test.id});
      } else {
        this.$store.dispatch('test/giveAdditionalTry', {studentId, testId: test.id});
      }
    },
    removeExtraTry(studentId, testId): void {
      this.$store.dispatch('test/removeAdditionalTry', {studentId, testId});
    },
    hasExtraTry(studentId, testId): boolean {
      return !!ExtraTryTests.query().where(ett => ett.studentId === studentId && ett.testId === testId).first();
    },
    onPrintResults(ps): void {
      try {
        const printWindow = window.open('', 'PRINT', 'height=600,width=800');
        const content: any = document.getElementById(`print-${ps.id}-${this.tabIndex[ps.student.id]}`).innerHTML;
        const template = `
<html>
<head>
<link rel="stylesheet" href="/print.css">
</head>
<body onload="window.focus()">
<button class="no-print" onclick="window.print()">Print</button>
<button class="no-print" onclick="window.close()">Close</button>
<div class="container">
<table>
<tr><th>Student</th><td>${ps.student.fullName}</td></tr>
<tr><th>Date</th><td>${new Date()}</td></tr>
</table>
<br>
<br>
<br>
<div class="mt-4">${content}</div>
</div>
</body>
</html>
`;
        printWindow.document.write(template);
        printWindow.document.close();
        printWindow.focus(); // necessary for IE >= 10*/
      } catch (e) {
        self.print();
      }
    },
  },
});
